<template>
<div>
<div style="margin-top: 100px">
    <BasePageBreadCrumb :pages="pages" :title="`${pagination.itens} pedidos`" :menu="menu"/>
    
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12 p-0 d-none d-md-block">
            <div class="card-group">
                <div class="card border-right">
                    <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div class="d-inline-flex align-items-center">
                                    <h2 class="text-dark mb-1 font-weight-medium">{{ pagination.itens }}</h2>
                                </div>
                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">novos pedidos</h6>
                            </div>
                            <div class="ml-auto mt-md-3 mt-lg-0">
                                <span class="opacity-7 text-muted"><i class="far fa-list-alt font-25"></i></span>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card border-right">
                    <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <h2 class="text-dark mb-1 w-100 text-truncate font-weight-medium">0</h2>
                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">em andamentos</h6>
                            </div>
                            <div class="ml-auto mt-md-3 mt-lg-0 pr-1">
                                <span class="opacity-7 text-muted"><i class="far fa-hourglass font-25"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-right">
                    <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div class="d-inline-flex align-items-center">
                                    <h2 class="text-dark mb-1 font-weight-medium">0</h2>
                                </div>
                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">finalizados</h6>
                            </div>
                            <div class="ml-auto mt-md-3 mt-lg-0">
                                <span class="opacity-7 text-muted"><i class="far fa-thumbs-up font-25"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <h2 class="text-dark mb-1 font-weight-medium">{{ pagination.itens }}</h2>
                                <h6 class="text-muted font-weight-normal mb-0 w-100 text-truncate">todos</h6>
                            </div>
                            <div class="ml-auto mt-md-3 mt-lg-0">
                                <span class="opacity-7 text-muted"><i class="icon-globe font-29"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
             <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar">
                                <button type="submit" class="btn btn-info">Buscar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-11 col-lg-9 pb-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 col-md-6 col-lg-auto p-1">
                                <select class="custom-select mr-sm-2 campo-filter-1" id="inlineFormCustomSelect">
                                    <option value="Google">Tipo</option>
                                    <option value="Google">Sem extensão</option>
                                    <option value="Google">Com extensão</option>
                                </select>
                            </div>
                            <div class="col-6 col-md-6 col-lg-auto p-1">
                                <select class="custom-select mr-sm-2 campo-filter-2" v-model="status">
                                    <option :value="null">Todos</option>
                                    <option value="NEW">Novos pedidos</option>
                                    <option value="IN_PROGRESS">Em andamentos</option>
                                    <option value="FINISHED">Finalizados</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                            <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                        <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                        </div>
                                        <div class="col-7 col-md-8 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Imóvel</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-3 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">Nome</p>
                                                    </div>
                                                    <div class="col-8 col-md-4 col-lg-2 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">N˚ apto</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Pedido</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-list" v-for="item in orders" :key="item.id" :style="{'background-color': !item.is_active ? '#f3f3f3' : '#fff'}"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row" >
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <img v-if="item.enterprise.image" :src="item.enterprise.image" alt="user" class="rounded-circle card-body-list-img" />
                                        </div>
                                        <div class="col-7 col-md-6 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                        <h6 class="text-truncate cod"><span>CÓD. PEDIDO:</span> {{ item.id }}</h6>
                                                        <h6 class="text-truncate card-body-list-title d-none d-lg-block">{{ item.enterprise.name }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                        <h6 class="text-truncate card-body-list-title">{{ item.user?.first_name }} {{ item.user?.last_name }}</h6>
                                                        <h6 class="text-truncate cpf d-none d-lg-block">{{ item.user?.cpf }}</h6>
                                                    </div>
                                                    <div class="col-8 col-md-4 col-lg-2 align-self-center pad-content-list">
                                                        <h6 class="text-truncate apto">{{ item.unity.number }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <h6 class="text-truncate cod-tec">{{ item.created_at | date }}</h6>
                                                        <h6 class="text-truncate empreendi">{{ item.kit_name }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <b-link :to="{name: 'InformationOrder', params: {id: item.id}}" class=" ml-auto btn btn-dark rounded-circle btn-circle font-20"><i class="text-white fas fa-eye"></i></b-link>
                                            <!-- <a class="btn btn-dark rounded-circle btn-circle font-20" data-bs-toggle="modal" data-bs-target="#right-modal-kit"  @click="openCheckout(item)"><i class="text-white fas fa-eye"></i></a> -->
                                            <a class="btn btn-danger text-white rounded-circle btn-circle font-16 ml-2" href="javascript:void(0);" @click="removeKit(item.id)"><i class="far fa-trash-alt"></i></a>
                                        </div>
                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 text-center p-4" v-if="orders.length == 0">
                        Nenhum pedido encontrado.
                    </div>
                        
                   
                </div>
            </div>
            <div class="col-12">
                <BasePagination
                    v-if="orders.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage" />
            </div>
            </div>
        </div>
</div>
    <Checkout :order="order" :readOnly="true"/>
</div>
</template>
<script>
import OrderService from "@/services/resources/OrderService";
import Checkout from "@/components/Kit/Checkout.vue";
import moment from 'moment'
const service = OrderService.build();

export default {
    components:{
        Checkout
    },
    data(){
        return{
            orders: [],
            order: {
                itens: [],
                kits: [],
                price: 0
            },
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            search: null,
            status: null,
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'pedidos',
                    to: 'Order'
                }
            ],
            menu:[
                {
                    name: 'Pedidos',
                    to: 'Order'
                }
            ]
        }
    },
    filters:{
      date(date){
          return moment(date).format('DD/MM/YYYY H:MM');
      }
    },
    watch:{
        status(){
            this.fetchOrders();
        }
    },
    methods:{
        openCheckout(item){

            this.order = item;
            this.order.kits = item.kits
              //  console.log(elem);
              //  let kit = elem.kit;

                // kit.products = kit.products.reduce(function (r, a) {
                //         let name = a.product.subdivision ? a.product.subdivision.name : 'Todos';
                //         r[name] = r[name] || [];
                //         r[name].push(a);
                //         return r;
             //   // }, Object.create(null));
             //   return kit;
           // })

            this.order.itens = item.itens.map(elem => {
                return {item: elem.kit_config, };
            })
        
        },
        toPage(page) {
            this.pagination.currentPage = page;
            this.fetchOrders();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchOrders();
        },
        fetchOrders(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.search){
                data['search'] = this.search;
            }

            if(this.status){
                data['status'] = this.status;
            }

             service
                .search(data)
                .then(resp => {
                    this.orders = resp.data.map(item => {
                        return{
                            ...item,
                            kit_name: 'Personalização de kit acabamentos'
                            // kit_name: typeof item.kits[0].name !== 'undefined' ? item.kits[0].name :
                            //  typeof item.kits[0].kit.name !== 'undefined'? item.kits[0].kit.name : 'INDEFINIDO',
                            

                        }
                    });
                    this.pagination.totalPages = resp.last_page;
                    this.pagination.itens = resp.total;
                })
                .catch(err => {
                console.log(err)
                })
        },
        removeKit(id){

            this.$bvModal.msgBoxConfirm('Tem certeza que deseja excluir esse pedido?')
            .then(value => {

                if(value) {
                    let data = {
                    id: id
                    };

                    service
                    .destroy(data)
                    .then(resp => {
                        this.$bvToast.toast('Seu pedido foi excluído com sucesso!', {
                            title: 'Excluir pedido',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                        this.fetchOrders();
                    })
                    .catch(err => {
                        this.$bvToast.toast('Seu pedido não pode ser excluído.', {
                            title: 'Excluir pedido',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }
                
            })
            .catch(err => {
            // An error occurred
            })
            

        },
    },
    mounted(){
        this.fetchOrders();
    }
}
</script>